import React from 'react'
import Layout from '../../components/containers/layout'
import Seo from "../../components/seo/seo"
import Navbar from '../../components/nav/navbar'
import Footer from '../../components/nav/footer'
import Hr from '../../components/hr/hr.js'
import Oncology from '../../components/containers/services/onkologia.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/oferta/okologia.jpg")

const Onkologia = () => (
  <Layout>
    <Seo title="Weterynarz - onkologia"
         description="Diagnostyka, leczenie i opieka paliatywna pacjentów onkologicznych"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "Onkologia" }/>
    <Oncology />
    <Footer />
  </Layout>
)
export default Onkologia;

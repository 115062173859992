import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/oferta/"

const Oncology = () => (
  <Container className="text_container">
  <StaticImage variant="top" src={path + "onkologia.jpg"} alt="Onkologia" height={400}/>
    <div className="divider"></div>
    <p>
    Badanie onkologiczne wykonuje lek. wet. Tadeusz Czekalski
    Zajmujemy się diagnozowaniem, leczeniem i opieką paliatywną pacjentów onkologicznych. W naszej
    lecznicy jest możliwość leczenia za pomocą chemioterapii oraz wykonujemy skomplikowane zabiegi
    onkologiczne.
    </p>
  </Container>
);

export default Oncology;
